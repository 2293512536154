import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { RetryLink } from "@apollo/client/link/retry";
import { relayStylePagination } from "@apollo/client/utilities";
import { useIonToast } from "@ionic/react";
import { createConsumer } from "@rails/actioncable";
import { withScalars } from "apollo-link-scalars";
import { sha256 } from "crypto-hash";
import { buildClientSchema, IntrospectionQuery } from "graphql";
import { ComponentProps, useCallback, useContext, useMemo } from "react";

// eslint-disable-next-line no-restricted-imports
import introspectionResult from "../schema.json";

import { clearAuthTokens, setOtpVerified } from "@actions/sessionActions";
import { sessionContext } from "@context/Contexts";
import ActionCableLink from "@utils/ActionCableLink";
import { IconList } from "@utils/iconUtils";

type Props = Omit<ComponentProps<typeof ApolloProvider>, "client">;

const DateResolver = {
  parseValue: (raw: unknown): Date | null => {
    if (!raw) {
      return null;
    }

    if (typeof raw === "string" || raw instanceof String) {
      const rawString = raw as string;

      const dateMatch = /^(\d\d\d\d)-(\d+)-(\d+)$/.exec(rawString);
      if (dateMatch) {
        const year = parseInt(dateMatch[1]);
        const month = parseInt(dateMatch[2]) - 1;
        const date = parseInt(dateMatch[3]);

        const result = new Date(year, month, date);
        return result;
      }

      const timestampMatch = /^(\d\d\d\d)-(\d+)-(\d+)T(\d+):(\d+):(\d+)Z$/.exec(rawString);

      if (timestampMatch) {
        const year = parseInt(timestampMatch[1]);
        const month = parseInt(timestampMatch[2]) - 1;
        const date = parseInt(timestampMatch[3]);

        const hour = parseInt(timestampMatch[4]);
        const minute = parseInt(timestampMatch[5]);
        const second = parseInt(timestampMatch[6]);

        const result = new Date(Date.UTC(year, month, date, hour, minute, second));
        return result;
      }

      // There appears to be no way to create a JS date with an arbitrary timezone, but it can
      // parse properly ISO8601-formatted timestamps that you pass in with a timezone, which
      // is exhausting.
      const nonUtcTimestampMatch = /^(\d\d\d\d)-(\d+)-(\d+)T(\d+):(\d+):(\d+)[+-](\d+):(\d+)$/.exec(rawString);

      if (nonUtcTimestampMatch) {
        return new Date(rawString);
      }
    }

    throw new Error("invalid timestamp to parse");
  },
  serialize: (parsed: unknown): string | null => {
    if (typeof parsed === "string" || parsed instanceof String) {
      return parsed as string;
    }
    if (parsed instanceof Date) {
      return parsed.toISOString();
    }
    return null;
  }
};

const ApiProvider = ({ children, ...apolloProps }: Props) => {
  const { dispatch: sessionDispatch, sessionState } = useContext(sessionContext);

  const getActiveToken = useCallback(
    (overrideToken?: string | null) => {
      const impersonatingExpertAuthToken = sessionState.impersonateExpertAuthToken;
      const impersonatingClientAuthToken = sessionState.impersonateClientAuthToken;
      const authToken = sessionState.authToken;

      return overrideToken ?? impersonatingClientAuthToken ?? impersonatingExpertAuthToken ?? authToken;
    },
    [sessionState.authToken, sessionState.impersonateClientAuthToken, sessionState.impersonateExpertAuthToken]
  );

  const cache = useMemo(
    () =>
      new InMemoryCache({
        typePolicies: {
          ConsultAvailabilityDay: {
            fields: {
              availabilities: {
                merge: (_existing = [], incoming: any[]) => incoming
              }
            }
          },
          Query: {
            fields: {
              activities: relayStylePagination(["activityKinds", "actorKind", "clientId", "expertId", "startTime"]),
              articles: relayStylePagination([
                "contentConditionKinds",
                "contentTypes",
                "focusArea",
                "search",
                "sortBy",
                "sortDirection"
              ]),
              automations: relayStylePagination(["searchTerm", "soryBy", "sortDirection"]),
              carePlanTaskTemplates: relayStylePagination([
                "focusArea",
                "searchTerm",
                "userType",
                "sortBy",
                "sortDirection"
              ]),
              careProfileChanges: relayStylePagination(["endDate", "journeyId", "startDate"]),
              chatMessages: relayStylePagination(["id"]),
              drugsSearch: relayStylePagination(["search"]),
              emailMessages: relayStylePagination(),
              employees: relayStylePagination(["employerId", "search", "sortBy", "sortDirection"]),
              employerBenefits: relayStylePagination(["category", "journeyId", "search"]),
              employers: relayStylePagination(["searchTerm", "sortBy", "sortDirection"]),
              expertCarePlanTurnAroundTimes: relayStylePagination([
                "endDate",
                "expertId",
                "sortBy",
                "sortDirection",
                "startDate"
              ]),
              expertCarePlans: relayStylePagination([
                "expertArchived",
                "expertId",
                "icrStatus",
                "sortBy",
                "sortDirection",
                "status"
              ]),
              expertClients: relayStylePagination([
                "endDate",
                "expertId",
                "primaryJourneys",
                "searchTerm",
                "sortBy",
                "sortDirection",
                "startDate"
              ]),
              expertConsults: relayStylePagination([
                "consultReportStatus",
                "duration",
                "employerId",
                "endDate",
                "expertId",
                "forExpert",
                "needsInterpretation",
                "position",
                "selfPaid",
                "sortBy",
                "sortDirection",
                "startDate",
                "timePeriod"
              ]),
              expertJourneys: relayStylePagination([
                "allJourneys",
                "employerId",
                "expertArchived",
                "expertUnopened",
                "expertId",
                "primaryExpertId",
                "role",
                "search",
                "selfPaid",
                "sortBy",
                "sortDirection",
                "statuses"
              ]),
              expertSummaries: relayStylePagination(["sortBy", "sortDirection"]),
              expertTasks: relayStylePagination([
                "assignee",
                "clientId",
                "endTime",
                "expertId",
                "sortBy",
                "sortDirection",
                "status"
              ]),
              expertUnreadMessages: relayStylePagination(),
              experts: relayStylePagination(["searchTerm", "sortBy", "sortDirection"]),
              goalTemplates: relayStylePagination([
                "archived",
                "focusArea",
                "journeyId",
                "kind",
                "searchTerm",
                "sortBy",
                "sortDirection",
                "usedInJourneyId"
              ]),
              journeyTaggings: relayStylePagination([
                "employerId",
                "endDate",
                "searchTerm",
                "selfPaid",
                "sortBy",
                "sortDirection",
                "startDate",
                "status",
                "tag"
              ]),
              journeyTags: relayStylePagination([
                "employerId",
                "endDate",
                "expertId",
                "searchTerm",
                "selfPaid",
                "sortBy",
                "sortDirection",
                "startDate"
              ]),
              journeyTasks: relayStylePagination(["assignee", "journeyId", "status"]),
              journeys: relayStylePagination([
                "active",
                "category",
                "consultComplete",
                "employerId",
                "expertId",
                "search",
                "selfPaid",
                "sortBy",
                "sortDirection"
              ]),
              medicalConditionSearch: relayStylePagination(["search"]),
              messageTemplateCategories: relayStylePagination(["search", "sortBy", "sortDirection"]),
              messageTemplates: relayStylePagination(["categoryId", "search", "sortBy", "sortDirection"]),
              messages: relayStylePagination(),
              packageGrants: relayStylePagination([
                "eligibilityStatus",
                "employerId",
                "selfPaid",
                "sortBy",
                "sortDirection"
              ]),
              pins: {
                merge: (_existing = [], incoming: any[]) => incoming
              },
              resourceFeatureTemplates: relayStylePagination(["search"]),
              surveyResponses: relayStylePagination(["endDate", "expertId", "service", "startDate"]),
              timeLogs: relayStylePagination(["billable", "endDate", "expertId", "group", "journeyId", "startDate"]),
              worksheetTemplates: relayStylePagination([
                "focusArea",
                "archived",
                "searchTerm",
                "sortBy",
                "sortDirection"
              ])
            }
          }
        }
      }),
    []
  );

  const httpLink = useMemo(() => new HttpLink({ uri: `${import.meta.env.VITE_API_HOST}/graphql` }), []);

  const schema = useMemo(() => buildClientSchema(introspectionResult as unknown as IntrospectionQuery), []);

  const scalarLink = useMemo(() => {
    const typesMap = {
      ISO8601Date: DateResolver,
      ISO8601DateTime: DateResolver
    };

    return withScalars({ schema, typesMap });
  }, [schema]);

  const authLink = useMemo(
    () =>
      setContext((_, { authToken: overrideToken, headers }) => {
        const stringOverrideToken = overrideToken as string | null;
        const token = getActiveToken(stringOverrideToken);

        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : ""
          }
        };
      }),
    [getActiveToken]
  );

  const cableUrl = useMemo(() => {
    const url = import.meta.env.VITE_ACTIONCABLE_URL;
    if (url) {
      const token = getActiveToken();
      return `${url}?token=${token ?? ""}`;
    }
  }, [getActiveToken]);

  const splitLink = useMemo(() => {
    const cable = createConsumer(cableUrl ?? "");
    return ApolloLink.split(
      op =>
        op.query.definitions.some(
          definition => definition.kind === "OperationDefinition" && definition.operation === "subscription"
        ),
      // https://github.com/rmosolgo/graphql-ruby/issues/3236
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scalarLink.concat(new ActionCableLink({ cable })),
      authLink.concat(scalarLink.concat(httpLink))
    );
  }, [authLink, cableUrl, httpLink, scalarLink]);

  const [present, dismiss] = useIonToast();

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ locations, message, path }) => {
            // eslint-disable-next-line no-console
            console.log("[GraphQL error]: ", message, JSON.stringify(locations), path);
            if (message === "invalid_jwt") {
              sessionDispatch(clearAuthTokens());
            }
            // If you get an unauthorized and you have no tokens it probably means that you were logged out.
            if (message === "unauthorized") {
              if (!getActiveToken()) {
                sessionDispatch(clearAuthTokens());
              }
              sessionStorage.removeItem("postLoginRedirectTo");
            }
            if (message === "2fa_required") {
              sessionDispatch(setOtpVerified(false));
            }
          });
        }

        if (networkError) {
          // eslint-disable-next-line no-console
          console.log("[Network error]:", networkError);
          present({
            buttons: [{ handler: () => dismiss(), icon: IconList.xmark }],
            color: "danger",
            cssClass: "toast",
            duration: 30000,
            message: "Grayce can't connect to the internet. Check your connection and try again.",
            position: "top"
          });
        }
      }),
    [dismiss, getActiveToken, present, sessionDispatch]
  );

  const retryLink = useMemo(
    () =>
      new RetryLink({
        attempts: { max: 5 },
        delay: {
          initial: 150,
          jitter: true,
          max: 1000
        }
      }),
    []
  );

  const persistedQueriesLink = useMemo(() => createPersistedQueryLink({ sha256 }), []);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        cache,
        link: errorLink.concat(retryLink.concat(persistedQueriesLink.concat(splitLink)))
      }),
    [cache, errorLink, persistedQueriesLink, retryLink, splitLink]
  );

  return (
    <ApolloProvider {...apolloProps} client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default ApiProvider;
