/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-restricted-syntax */

// This file is copied from the graphql-ruby client code here:
// https://github.com/rmosolgo/graphql-ruby/blob/172dde003afc7653f5d7f3b06f6687e3ee663ebd/javascript_client/src/subscriptions/ActionCableLink.ts
// I copied it into the app because it does not have any other dependencies in that code and because
// the graphql-ruby-client library seems to be abandoned otherwise, which was trapping us on a very
// old version of the graphql package.

import { ApolloLink, FetchResult, NextLink, Observable, Operation } from "@apollo/client/core";
import { print } from "graphql";

import type { Consumer } from "@rails/actioncable";

type RequestResult = FetchResult<{ [key: string]: any }>;
type ConnectionParams = object | ((operation: Operation) => object);

class ActionCableLink extends ApolloLink {
  actionName: string;
  cable: Consumer;
  channelName: string;
  connectionParams: ConnectionParams;

  constructor(options: {
    actionName?: string;
    cable: Consumer;
    channelName?: string;
    connectionParams?: ConnectionParams;
  }) {
    super();
    this.cable = options.cable;
    this.channelName = options.channelName ?? "GraphqlChannel";
    this.actionName = options.actionName ?? "execute";
    this.connectionParams = options.connectionParams ?? {};
  }

  // Interestingly, this link does _not_ call through to `next` because
  // instead, it sends the request to ActionCable.
  request(operation: Operation, _next: NextLink): Observable<RequestResult> {
    return new Observable(observer => {
      const channelId = Math.round(Date.now() + Math.random() * 100000).toString(16);
      const actionName = this.actionName;
      const connectionParams =
        typeof this.connectionParams === "function" ? this.connectionParams(operation) : this.connectionParams;
      const channel = this.cable.subscriptions.create(
        Object.assign(
          {},
          {
            channel: this.channelName,
            channelId
          },
          connectionParams
        ),
        {
          connected: function () {
            channel.perform(actionName, {
              // This is added for persisted operation support:
              operationId: (operation as { operationId?: string }).operationId,
              operationName: operation.operationName,
              query: operation.query ? print(operation.query) : null,
              variables: operation.variables
            });
          },
          received: function (payload) {
            if (payload.result.data || payload.result.errors) {
              observer.next(payload.result);
            }

            if (!payload.more) {
              observer.complete();
            }
          }
        }
      );
      // Make the ActionCable subscription behave like an Apollo subscription
      return Object.assign(channel, { closed: false });
    });
  }
}

export default ActionCableLink;
