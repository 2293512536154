import { createContext } from "react";

import {
  AppUpdatedContextType,
  BannerContextType,
  CookieConsentContextType,
  ErrorContextType,
  FullscreenModalContextType,
  OpenerContextType,
  ScrollPositionContextType,
  SessionContextType
} from "@typing/State";

export const appUpdatedContext = createContext<AppUpdatedContextType>({} as AppUpdatedContextType);
export const bannerContext = createContext<BannerContextType>({} as BannerContextType);
export const cookieConsentContext = createContext<CookieConsentContextType>({} as CookieConsentContextType);
export const errorContext = createContext<ErrorContextType>({} as ErrorContextType);
export const fullscreenModalContext = createContext<FullscreenModalContextType>({} as FullscreenModalContextType);
export const openerContext = createContext<OpenerContextType>({} as OpenerContextType);
export const scrollPositionContext = createContext<ScrollPositionContextType>({} as ScrollPositionContextType);
export const sessionContext = createContext<SessionContextType>({} as SessionContextType);
