import { GetMeQuery } from "@typing/Generated";

type Me =
  | Extract<GetMeQuery["me"], { __typename: "Admin" }>
  | Extract<GetMeQuery["me"], { __typename: "Client" }>
  | Extract<GetMeQuery["me"], { __typename: "Expert" }>;

export enum SessionAction {
  CLEAR_AUTH_TOKENS = "auth/CLEAR_AUTH_TOKENS",
  END_IMPERSONATION = "auth/END_IMPERSONATION",
  SET_AUTH_TOKEN = "auth/SET_AUTH_TOKEN",
  SET_IMPERSONATING_CLIENT = "auth/SET_IMPERSONATING_CLIENT",
  SET_IMPERSONATING_EXPERT = "auth/SET_IMPERSONATING_EXPERT",
  SET_ME = "auth/SET_ME",
  SET_OTP_VERIFIED = "auth/SET_OTP_VERIFIED"
}

interface ClearAuthTokensAction {
  type: SessionAction.CLEAR_AUTH_TOKENS;
}

interface EndImpersonationAction {
  payload: {
    user: Me;
  };
  type: SessionAction.END_IMPERSONATION;
}

interface SetAuthTokenAction {
  payload: {
    authToken: string;
    otpVerified: boolean;
    user: Me;
  };
  type: SessionAction.SET_AUTH_TOKEN;
}

interface SetImpersonatingClientAction {
  payload: {
    authToken: string;
    user: Me;
  };
  type: SessionAction.SET_IMPERSONATING_CLIENT;
}

interface SetImpersonatingExpertAction {
  payload: {
    authToken: string;
    user: Me;
  };
  type: SessionAction.SET_IMPERSONATING_EXPERT;
}

interface SetMeAction {
  payload: {
    user: Me;
  };
  type: SessionAction.SET_ME;
}

interface SetOtpVerifiedAction {
  payload: {
    otpVerified: boolean;
  };
  type: SessionAction.SET_OTP_VERIFIED;
}

export type SessionActionTypes =
  | ClearAuthTokensAction
  | EndImpersonationAction
  | SetAuthTokenAction
  | SetImpersonatingClientAction
  | SetImpersonatingExpertAction
  | SetOtpVerifiedAction
  | SetMeAction;

export const clearAuthTokens: () => ClearAuthTokensAction = () => ({
  type: SessionAction.CLEAR_AUTH_TOKENS
});

export const endImpersonation: (user: Me) => EndImpersonationAction = user => ({
  payload: { user },
  type: SessionAction.END_IMPERSONATION
});

export const setOtpVerified: (optVerified: boolean) => SetOtpVerifiedAction = otpVerified => ({
  payload: { otpVerified },
  type: SessionAction.SET_OTP_VERIFIED
});

export const setAuthToken: (authToken: string, user: Me, otpVerified?: boolean) => SetAuthTokenAction = (
  authToken,
  user,
  otpVerified = true
) => ({
  payload: { authToken, otpVerified, user },
  type: SessionAction.SET_AUTH_TOKEN
});

export const setMe: (user: Me) => SetMeAction = user => ({
  payload: { user },
  type: SessionAction.SET_ME
});

export const setImpersonatingClient: (authToken: string, user: Me) => SetImpersonatingClientAction = (
  authToken,
  user
) => ({
  payload: { authToken, user },
  type: SessionAction.SET_IMPERSONATING_CLIENT
});

export const setImpersonatingExpert: (authToken: string, user: Me) => SetImpersonatingExpertAction = (
  authToken,
  user
) => ({
  payload: { authToken, user },
  type: SessionAction.SET_IMPERSONATING_EXPERT
});
