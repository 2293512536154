import {
  addDays,
  differenceInCalendarDays,
  endOfMonth,
  formatDistanceStrict,
  isFuture,
  isMonday,
  isSunday,
  isToday,
  isTomorrow,
  isYesterday,
  previousMonday,
  previousSunday,
  startOfMonth
} from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { IntlShape } from "react-intl";

import DateAndTimeStamp from "@components/dates/DateAndTimeStamp";
import timezones, { suggestedTimeZones } from "@i18n/timezones";
import { ConsultFragment as Consult } from "@typing/Generated";

type Args = {
  dropZeroMinutes?: boolean;
  minutes: number | undefined;
  negateDebits?: boolean;
  roundRemainder?: boolean;
};

export const daysAgo = (date: Date): string => {
  const now = new Date();
  const inputDate = date;

  return formatDistanceStrict(inputDate, now, { addSuffix: true });
};

export const minutesToHoursAndMinutes = ({
  dropZeroMinutes = false,
  minutes,
  negateDebits = false,
  roundRemainder = false
}: Args) => {
  if (minutes === undefined) {
    return "0";
  }

  const negative = minutes < 0;

  const hours = Math.floor(Math.abs(minutes) / 60);
  let remainder = (Math.abs(minutes) % 60).toString();

  if (remainder[1] === ".") {
    remainder = `0${remainder}`;
  }

  if (roundRemainder) {
    remainder = remainder.slice(0, 2);
  }
  if (remainder.length === 1) {
    remainder = `0${remainder}`;
  }

  if (remainder === "00" && dropZeroMinutes) {
    if (negateDebits) {
      return `${negative ? "" : "+"}${hours}`;
    }
    return `${negative ? "-" : ""}${hours}`;
  }

  if (negateDebits) {
    return `${negative ? "" : "+"}${hours}:${remainder}`;
  }
  return `${negative ? "-" : ""}${hours}:${remainder}`;
};

export const isValidTimezone = (tz: string | null | undefined) => {
  if (!tz) {
    return false;
  }

  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    return true;
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch {
    return false;
  }
};

// For some reason toLocaleString will produce an hour of 24 when the time rolls over to the next day, and
// trying to get Date to parse that will produce an invalid result. This rolls it back over to zero.
const ensureTimeDidNotRollOver = (timeString: string) => {
  const parts = /(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)/.exec(timeString);
  if (parts) {
    let hour = parseInt(parts[4]);
    if (hour > 23) {
      hour -= 24;
      return `${parts[1]}-${parts[2]}-${parts[3]}T0${hour}:${parts[5]}:${parts[6]}`;
    }
  }

  return timeString;
};

// https://stackoverflow.com/questions/29265389/how-do-i-calculate-the-difference-of-2-time-zones-in-javascript
// Give this function two timezone identifiers (the ones that look like "America/New_York") and
// it will return the number of minutes between them. A negative number means that the second timezone
// is behind the first one, while a positive number means that it is ahead. Note that the difference between two
// timezones is not necessarily a whole hour, some zones are offset by 30 or even 45 minutes.
const compareTimezones = (tz1: string, tz2: string, compareDate: Date) => {
  try {
    let iso1 = compareDate.toLocaleString("en-CA", { hour12: false, timeZone: tz1 }).replace(", ", "T");
    const dateParts1 = iso1.split("T")[0].split("/");
    iso1 = `${dateParts1[2]}-${dateParts1[0].padStart(2, "0")}-${dateParts1[1].padStart(2, "0")}T${iso1.split("T")[1]}`;
    iso1 = ensureTimeDidNotRollOver(iso1);
    iso1 += "." + compareDate.getMilliseconds().toString().padStart(3, "0");
    const lie1 = new Date(iso1 + "Z");
    let iso2 = compareDate.toLocaleString("en-CA", { hour12: false, timeZone: tz2 }).replace(", ", "T");
    const dateParts2 = iso2.split("T")[0].split("/");
    iso2 = `${dateParts2[2]}-${dateParts2[0].padStart(2, "0")}-${dateParts2[1].padStart(2, "0")}T${iso2.split("T")[1]}`;
    iso2 = ensureTimeDidNotRollOver(iso2);
    iso2 += "." + compareDate.getMilliseconds().toString().padStart(3, "0");
    const lie2 = new Date(iso2 + "Z");
    const answer = -(lie1.getTime() - lie2.getTime()) / 60 / 1000;
    return answer;
  } catch {
    let iso1 = compareDate.toLocaleString("en-CA", { hour12: false, timeZone: tz1 }).replace(", ", "T");
    iso1 = ensureTimeDidNotRollOver(iso1);
    iso1 += "." + compareDate.getMilliseconds().toString().padStart(3, "0");
    const lie1 = new Date(iso1 + "Z");
    let iso2 = compareDate.toLocaleString("en-CA", { hour12: false, timeZone: tz2 }).replace(", ", "T");
    iso2 = ensureTimeDidNotRollOver(iso2);
    iso2 += "." + compareDate.getMilliseconds().toString().padStart(3, "0");
    const lie2 = new Date(iso2 + "Z");
    return -(lie1.getTime() - lie2.getTime()) / 60 / 1000;
  }
};

// Give this function a timezone identifier (the one that looks like "America/New_York") and it
// will return the abbreviated version of that, which is the thing that looks like "EDT".
// Also, I hate timezones.
export const timezoneAbbreviation = (timezone: string, atTime: Date = new Date()) => {
  let timeString = atTime.toLocaleString("en-CA", { hour12: false, timeZone: timezone, timeZoneName: "short" });
  timeString = ensureTimeDidNotRollOver(timeString);
  const match = /.* ([^\s]+)$/.exec(timeString);
  if (match) {
    return match[1];
  }
};

export const convertTZ = (date: Date, tzString: string) =>
  date
    .toLocaleString("en-CA", {
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      month: "long",
      timeZone: tzString,
      year: "numeric"
    })
    .replace("a.m.", "AM")
    .replace("p.m.", "PM");

export const dateStringForGroup = (date: Date, intl: IntlShape) => {
  if (isToday(date)) {
    return intl.formatMessage(
      { id: "pages.expert.tasks.days.today" },
      { date: intl.formatDate(date, { day: "numeric", month: "long" }) }
    );
  }
  if (isTomorrow(date)) {
    return intl.formatMessage(
      { id: "pages.expert.tasks.days.tomorrow" },
      { date: intl.formatDate(date, { day: "numeric", month: "long" }) }
    );
  }
  return intl.formatMessage(
    { id: "pages.expert.tasks.days.weekday" },
    {
      date: intl.formatDate(date, { day: "numeric", month: "long" }),
      day: intl.formatDate(date, { weekday: "long" })
    }
  );
};

export const stringForDateTime = (date: Date, intl: IntlShape) => {
  if (isToday(date)) {
    return intl.formatMessage({ id: "pages.expert.tasks.time.today" }, { time: intl.formatTime(date) });
  }
  if (isYesterday(date)) {
    return intl.formatMessage({ id: "pages.expert.tasks.time.yesterday" }, { time: intl.formatTime(date) });
  }
  if (isTomorrow(date)) {
    return intl.formatMessage({ id: "pages.expert.tasks.time.tomorrow" }, { time: intl.formatTime(date) });
  }
  return DateAndTimeStamp({ showTimezone: false, value: date });
};

const timezoneOptionForName = (tzName: string, key: string, isMulti = false) => {
  const offset = compareTimezones("UTC", tzName, new Date());
  const offsetLabel =
    offset >= 0
      ? `GMT+${minutesToHoursAndMinutes({ dropZeroMinutes: true, minutes: offset })}`
      : `GMT${minutesToHoursAndMinutes({ dropZeroMinutes: true, minutes: offset })}`;
  return {
    color: isMulti ? "#6ab7d0" : undefined,
    key,
    label: `(${offsetLabel}) ${timezones[tzName]}`,
    offset,
    value: tzName
  };
};

export const dateToMDYString = (date: Date | string): string => new Date(date).toISOString().split("T")[0];

type Option = {
  key: string;
  label: string;
  offset: number;
  value: string;
};

export const timezoneSelectOptions: (isMulti?: boolean) => Option[] = (isMulti?: boolean) => {
  let basicOptions = Object.keys(timezones).map(tzName => timezoneOptionForName(tzName, tzName, isMulti));
  basicOptions = basicOptions.sort((a, b) => (a.offset > b.offset ? 1 : -1));
  const suggestedOptions = suggestedTimeZones.map(tzName =>
    timezoneOptionForName(tzName, `${tzName} suggested`, isMulti)
  );
  return suggestedOptions.concat(basicOptions);
};

export const dateToIso8601 = (date: Date, intl: IntlShape) => {
  const dateParts = intl.formatDateToParts(date);
  const yearPart = dateParts.find(part => part.type === "year")?.value ?? "";
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const monthPart = ("0" + dateParts.find(part => part.type === "month")?.value).slice(-2) ?? "";
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const dayPart = ("0" + dateParts.find(part => part.type === "day")?.value).slice(-2) ?? "";

  const timeParts = intl.formatTimeToParts(date);
  let hourPart = timeParts.find(part => part.type === "hour")?.value ?? "";
  let minutePart = timeParts.find(part => part.type === "minute")?.value ?? "";
  const periodPart = timeParts.find(part => part.type === "dayPeriod")?.value ?? "";

  if (periodPart === "PM" && hourPart !== "12") {
    hourPart = (parseInt(hourPart, 10) + 12).toString();
  }

  if (periodPart === "AM" && hourPart === "12") {
    hourPart = "00";
  }

  if (hourPart.length === 1) {
    hourPart = "0" + hourPart;
  }

  if (minutePart.length === 1) {
    minutePart = "0" + minutePart;
  }

  return `${yearPart}-${monthPart}-${dayPart}T${hourPart}:${minutePart}:00`;
};

export const calculateDefaultDate = (date: Date | null | undefined) => {
  if (date) {
    return new Date(date).toISOString().split("T")[0];
  }
  return null;
};

export const dateAtCurrentTime = (dateIn: string | Date) => {
  const currentTime = new Date();
  const date = new Date(dateIn);
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();

  return new Date(year, month, day, hours, minutes, seconds);
};

export const getDaysOfTheWeekStartingWithSunday = (locale = "en-US", format: "short" | "long" = "short") => {
  const sunday = previousSunday(new Date());
  return [...Array(7).keys()].map(index => addDays(sunday, index).toLocaleString(locale, { weekday: format }));
};

export const getDaysOfTheWeekStartingWithMonday = (locale = "en-US", format: "short" | "long" = "short") => {
  const monday = previousMonday(new Date());
  return [...Array(7).keys()].map(index => addDays(monday, index).toLocaleString(locale, { weekday: format }));
};

type Day = {
  date: Date;
  index: number;
};

type Weeks = {
  [weekIndex: string]: Day[];
};

type GetWeeksForCurrentMonthArgs = {
  date: Date;
  startsOnMonday?: boolean;
};

export const getWeeksForCurrentMonth = ({ date, startsOnMonday = false }: GetWeeksForCurrentMonthArgs) => {
  const weeks: Weeks = {};
  const startDate = startsOnMonday
    ? isMonday(date)
      ? date
      : previousMonday(startOfMonth(date))
    : isSunday(date)
      ? date
      : previousSunday(startOfMonth(date));

  for (let i = 0; i <= differenceInCalendarDays(endOfMonth(date), startDate); i++) {
    const weekIndex = Math.floor(i / 7);
    if (weeks[weekIndex] === undefined) weeks[weekIndex] = [];
    const d = addDays(startDate, i);
    weeks[weekIndex].push({ date: d, index: d.getDay() });
  }

  return weeks;
};

export const browserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const consultEndsInFuture = (consult: Pick<Consult, "canceled" | "endTime">): boolean =>
  isFuture(consult.endTime) && !consult.canceled;

export const consultStartsInFuture = (consult: Pick<Consult, "canceled" | "startTime">): boolean =>
  isFuture(consult.startTime) && !consult.canceled;

export const dateStringFromDate = (date: Date, timezone: string | null | undefined) =>
  formatInTimeZone(date, timezone ?? "America/New_York", "yyyy-MM-dd");

export const getWeekdays = (locale = "en-US", format: "short" | "long" = "short") =>
  getDaysOfTheWeekStartingWithMonday(locale, format).slice(0, 5);
