import type { GlobalProvider } from "@ladle/react";
import React from "react";
import { IonApp, setupIonicReact } from "@ionic/react";

import ApiProvider from "../src/components/ApiProvider";
import IntlProvider from "../src/components/IntlProvider";
import SessionStore from "../src/context/SessionStore";

import enTranslations from "../src/i18n/en";
import storyTranslations from "./translations";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../src/theme/variables.css";
import "../src/theme/colors.css";
import "../src/theme/fonts.css";
import "../src/theme/styling.css";
import "../src/theme/print.css";
import "../src/theme/utilities.css";

setupIonicReact();

const translations = {
  ...enTranslations,
  ...storyTranslations
};

export const Provider: GlobalProvider = ({ children, globalState }) => (
  <SessionStore>
    <ApiProvider>
      <IntlProvider locale="en" messages={translations}>
        {children}
      </IntlProvider>
    </ApiProvider>
  </SessionStore>
);
